const axios = require('axios')

const uri = `https://api.z-api.io/instances/39B02889B1F8F087100B0E63F29971E4/token/910B942D5E19A92B9002ED80/send-messages`

const whatsapp = {
  send: async ({ phone, message }) => {
    phone = `55${phone.match(/\d/g).join('')}`
    let response
    try {
      // console.log(phone, message)
      response = await axios.post(uri, { phone, message })
    } catch (err) {
      console.error(
        '## BACKEND WHATSAPP # send # erro ao enviar mensagem no whatsapp',
        { error: err.message || err }
      )
      throw err
    }

    return response
  }
}

module.exports = whatsapp
