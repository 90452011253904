<template>
  <v-container>
    <v-card v-if="!syncing" color="secondary" dark>
      <v-card-title class="font-weight-medium" v-text="name" />
      <v-card-subtitle v-text="driverRegistration" />
      <v-card-text class="display-1 font-weight-medium" v-text="actualTime" />

      <v-card-text
        v-if="isDriving"
        class="text-h5
      font-weight-medium pt-0"
        v-text="vehicle"
      />

      <v-card-subtitle
        v-if="actualInterval?.state && !syncingInterval && journeyStarted"
        class=" font-weight-medium pt-0"
      >
        Sincronizado
        <v-icon :class="syncedIconClass" v-text="syncedIcon" />
      </v-card-subtitle>
      <v-card-subtitle v-if="syncingInterval" class=" font-weight-medium pt-0">
        Sincronizando
        <v-progress-circular indeterminate color="blue" size="15" />
      </v-card-subtitle>
    </v-card>

    <v-skeleton-loader v-if="syncing" class="mx-auto" type="card">
    </v-skeleton-loader>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  MODULE_NAME as AUTH,
  GET_USUARIO_MATRICULA as REGISTRATION,
  GET_USUARIO_NOME as NAME,
  GET_USUARIO_EMAIL as EMAIL
} from '@/store/auth'
import {
  MODULE_NAME as JORNADA,
  GET_TIME_LOCALE,
  GET_JORNADA_INICIADA,
  GET_STATE,
  FINAL_JORNADA
} from '@/store/jornada'
import { mdiClose, mdiCheck } from '@mdi/js'

export default {
  name: 'TimePanel',
  props: {
    actualInterval: {
      type: Object,
      default() {
        return {}
      }
    },
    syncingInterval: {
      type: Boolean,
      default: false
    },
    vehiclePlate: {
      type: String,
      default: null
    }
  },

  data: () => ({
    mdiCheck,
    mdiClose,
    syncing: false
  }),

  computed: {
    ...mapGetters(AUTH, {
      name: NAME,
      email: EMAIL,
      registration: REGISTRATION
    }),
    ...mapGetters(JORNADA, {
      time: GET_TIME_LOCALE,
      journeyStarted: GET_JORNADA_INICIADA,
      state: GET_STATE
    }),
    driverRegistration() {
      return `Matrícula: ${this.registration}`
    },
    actualTime() {
      return `Tempo: ${this.time}`
    },
    vehicle() {
      return `Veículo: ${this.vehiclePlate}`
    },
    isDriving() {
      return this.isActualInterval('DIRECAO')
    },
    syncedIconClass() {
      const defaultClass = 'mb-1 ml-1 rounded-xl '
      return {
        defaultClass,
        green: this.actualInterval.synced,
        red: !this.actualInterval.synced
      }
    },
    syncedIcon() {
      return this.actualInterval.synced ? mdiCheck : mdiClose
    }
  },

  methods: {
    isActualInterval(interval) {
      if (interval === FINAL_JORNADA) {
        return
      }
      return this.state === interval
    }
  },

  created() {
    this.syncing = true
    setTimeout(() => {
      this.syncing = false
    }, 1000)
  }
}
</script>
