import {
  INIT,
  STOP,
  SET_HISTORICO,
  SET_SYNC,
  SAVE_JORNADA,
  SET_DIRECAO,
  SET_INTERVALO_REFEICAO
} from '../constants'
import { GeolocationService } from './../../../services'
import { isPointWithinRadius } from 'geolib'
import {
  FETCH_ENTERPRISE,
  KEY,
  MODULE_NAME as ENTERPRISE
} from '../../enterprise'
import {
  MODULE_NAME as LUNCH_TIME,
  START_LUNCH_TIME
} from '../../lunchTimeControl'
import {
  MODULE_NAME as DRIVING_TIME,
  START_DRIVING_TIME,
  RESTART_DRIVING_TIME
} from '../../drivingTimeControl'
import { KEY_STORE } from '../../vehicles/constants'
import { http } from '../../../services/http'

async function isPointInCirlce({
  latitude,
  longitude,
  dispatch,
  action,
  actions
}) {
  dispatch(`${ENTERPRISE}/${FETCH_ENTERPRISE}`, null, { root: true })
  const enterprise = JSON.parse(localStorage.getItem(KEY))
  const tracker_id = localStorage.getItem(KEY_STORE)
  if (tracker_id && enterprise?.id && latitude && longitude) {
    const { coordinates } = enterprise.geojson.geometry
    const { radius } = enterprise.geojson.properties
    const isInEnterprise = isPointWithinRadius(
      {
        latitude,
        longitude
      },
      { latitude: coordinates[1], longitude: coordinates[0] },
      radius
    )
    if (action === SET_DIRECAO && isInEnterprise) {
      await http.post('/alerts/create', {
        tracker_id,
        date: new Date().toISOString(),
        flags: ['IN_COMPANY']
      })
    }

    if (actions[0] === SET_DIRECAO && isInEnterprise) {
      await http.post('/alerts/create', {
        tracker_id,
        date: new Date().toISOString(),
        flags: ['OUT_OF_COMPANY']
      })
    }
  }
}

let actions = []

export const registerJourney = (action, _stop = true, _sync = false) => async ({
  dispatch,
  commit
}) => {
  if (_stop) {
    dispatch(STOP)
  }

  let geolocation = null
  let res = {
    allowed: false,
    result: {
      message: 'Fetch location failed'
    }
  }

  if (navigator.onLine) {
    res = await GeolocationService.getLocation()
  } else {
    res = {
      allowed: false,
      result: { message: 'Error fetching location.' }
    }
  }

  const { allowed, result } = res

  if (allowed) {
    geolocation = {
      accuracy: result.coords.accuracy,
      latitude: result.coords.latitude,
      longitude: result.coords.longitude
    }
  } else {
    geolocation = { error: true, message: result.message }
  }
  actions.push(action)
  if (actions.length >= 2) {
    actions = actions.slice(actions.length - 2, actions.length)
  }
  if (action === SET_DIRECAO) {
    dispatch(`${DRIVING_TIME}/${START_DRIVING_TIME}`, null, { root: true })
  } else {
    dispatch(`${DRIVING_TIME}/${RESTART_DRIVING_TIME}`, null, { root: true })
  }
  if (
    action === SET_DIRECAO ||
    (actions[0] === SET_DIRECAO &&
      geolocation.latitude &&
      geolocation.longitude)
  ) {
    isPointInCirlce({ ...geolocation, dispatch, action, actions })
  }
  if (
    action === SET_INTERVALO_REFEICAO ||
    actions[actions.length - 2] === SET_INTERVALO_REFEICAO
  ) {
    dispatch(`${LUNCH_TIME}/${START_LUNCH_TIME}`, null, { root: true })
  }
  commit(action, geolocation)
  dispatch(SAVE_JORNADA)
  dispatch(SET_HISTORICO)
  if (_sync) {
    dispatch(SET_SYNC)
  }
  dispatch(INIT)
}
