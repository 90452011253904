<template>
  <v-text-field
    ref="password"
    type="password"
    counter="50"
    :value="value"
    :required="required"
    :id="id"
    :label="label"
    :prepend-icon="icons.mdiLock"
    :rules="rules"
    :disabled="disabled"
    @keydown.enter="keyDownEnter"
    @input="elInput"
  />
</template>

<script>
// import Joi from 'joi'
import { mdiLock } from '@mdi/js'
// import { messages } from './joyMessages'

export default {
  name: 'elPasswordField',
  props: {
    id: {
      type: String,
      default: 'password'
    },
    label: {
      type: String,
      default: 'Senha'
    },
    value: {
      type: String
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    next: {
      type: String
    }
  },
  data() {
    return {
      icons: { mdiLock },
      rules: [
        value => (value && value.length < 50) || 'Digite uma senha menor!',
        value => !!value || 'Campo Obrigatório'
      ]
    }
  },
  methods: {
    elInput(value) {
      this.$emit('input', value)
    },
    keyDownEnter() {
      if (this.next) {
        const nextItem = document.querySelector(`#${this.next}`)
        nextItem.focus()
        nextItem.click()
      }
    }
  }
}
</script>
