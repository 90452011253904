<template>
  <v-card
    :loading="loading"
    :disabled="loading"
    width="400"
    class="mx-auto mt-16 pa-2"
    elevation="8"
  >
    <!-- <elTitle
      title="Login"
      subtitle="Informe a credencial do usuário nos campos abaixo."
    /> -->
    <div class="d-flex justify-center mt-6">
      <v-img
        v-if="GET_MODOESCURO"
        max-width="400"
        justify="center"
        align="center"
        eager
        width="110"
        height="107"
        src="@/assets/geoLogo.svg"
        title="Georastreamento"
        alt="Georastreamento Logo"
        contain
      />
      <v-img
        v-else
        max-width="400"
        justify="center"
        align="center"
        eager
        width="110"
        height="107"
        src="@/assets/geoLogoColor.png"
        title="Georastreamento"
        alt="Georastreamento Logo"
        contain
      />
    </div>
    <v-card-subtitle class="d-flex justify-center caption">
      Informe as credenciais do usuário nos campos abaixo.
    </v-card-subtitle>
    <v-card-text>
      <v-form
        v-model="credentialWasInformed"
        ref="credentialForm"
        autocomplete="off"
      >
        <ElCPF
          ref="cpf"
          v-model="form.cpf"
          required
          next="password"
          :disabled="loading"
        />
        <elPasswordField
          v-model="form.password"
          required
          next="entrar"
          :disabled="loading"
        />
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn
        id="entrar"
        ref="botaoEntrar"
        color="primary"
        block
        large
        :disabled="loading || !credentialWasInformed"
        :loading="loading"
        @click.stop="signIn"
        >Entrar</v-btn
      >
    </v-card-actions>
    <v-snackbar v-model="error.show" color="red darken-4" top centered>
      <v-row class="text-center" dense align="center" align-content="center">
        <v-col cols="3">
          <v-icon color="yellow">{{ icons.mdiAlarmLight }}</v-icon>
        </v-col>
        <v-col class="">
          <span class="font-weight-bold text-uppercase">{{
            error.message
          }}</span>
        </v-col>
      </v-row>
    </v-snackbar>
    <!-- <pre>{{$data}}</pre> -->
  </v-card>
</template>

<script>
import { mdiAlarmLight } from '@mdi/js'

import { ElCPF, elPasswordField } from '@/components'
import { LoginService } from '@/services'
import { MODULE_NAME as CONFIG, GET_MODOESCURO } from '@/store/config.js'

let login

export default {
  name: 'loginPage',
  components: { ElCPF, elPasswordField },
  data() {
    return {
      icons: { mdiAlarmLight },
      loading: true,
      credentialWasInformed: false,
      form: {
        cpf: null,
        password: null
      },
      error: {
        show: false,
        message: null
      },
      mask: '###.###.###-##',
      phone: '4140028922'
    }
  },
  computed: {
    [GET_MODOESCURO]() {
      return this.$store.getters[`${CONFIG}/${GET_MODOESCURO}`]
    }
  },
  methods: {
    hideError() {
      this.error.show = false
      this.error.message = null
    },
    showError({ message }) {
      this.error.show = message !== ''
      this.error.message = message
      this.$refs.cpf.$el.focus()
    },
    validateForm() {
      this.$refs.credentialForm.validate()
      if (this.credentialWasInformed) {
        const { cpf, password } = this.form
        return Promise.resolve({ cpf, password })
      } else {
        const error = new Error('Preencha os campos corretamente')
        return Promise.reject(error)
      }
    },
    async signIn() {
      this.loading = true
      try {
        this.hideError()
        const { cpf, password } = await this.validateForm()
        await login.login(cpf, password)
      } catch (err) {
        this.showError(err)
      } finally {
        this.loading = false
      }
    }
  },
  mounted() {
    if (process.env.NODE_ENV === 'development') {
      // this.form.cpf = '11032470771'
      // this.form.cpf = ''
      this.form.password = '1123qqwe'
    }
    login = LoginService(this.$store)
    login.removeAuthToken()
    this.$nextTick(() => {
      this.loading = false
    })
  }
}
</script>
<style>
html {
  background-color: rgb(36, 83, 120);
}
</style>
