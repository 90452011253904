import {
  LOAD,
  INIT,
  STOP,
  CLEAR,
  SET_TEMPO_ATUAL,
  SET_INTERVAL,
  SAVE_JORNADA,
  SAVE_HISTORICO
} from '../constants'

import { getEmail } from '../util'

export default {
  [LOAD]: ({ commit, rootGetters }) => commit(LOAD, getEmail(rootGetters)),
  [INIT]({ commit, dispatch, getters }) {
    const { GET_JORNADA_INICIADA } = getters
    if (GET_JORNADA_INICIADA) {
      const _interval = setInterval(() => dispatch(SET_TEMPO_ATUAL), 1000)
      commit(SET_INTERVAL, _interval)
    }
  },
  [STOP]({ state, commit, dispatch }) {
    if (state.interval) {
      clearInterval(state.interval)
      commit(SET_INTERVAL, null)
      dispatch(SAVE_JORNADA)
      dispatch(SAVE_HISTORICO)
    }
  },
  [CLEAR]: ({ commit }) => commit(CLEAR)
}
