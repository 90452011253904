import { INICIO_JORNADA, FINAL_JORNADA } from '../constants'
import { getKeyJornada, getKeyHistorico, getKeySync } from '../util'

import { getValueAndParse } from './getValueAndParse'
import _state from './../state'

export function jornadaLoad(state, _email) {
  const keyJornada = getKeyJornada(_email)
  const keyHistorico = getKeyHistorico(_email)
  const keySync = getKeySync(_email)

  state.jornada = getValueAndParse(keyJornada, _state.jornada)
  state.sync = getValueAndParse(keySync, [])

  const cb = historico => {
    const inicioJornada = historico.find(h => h.state === INICIO_JORNADA)
    if (inicioJornada) {
      state.inicio = inicioJornada.inicio
    }
    const finalJornada = historico.find(h => h.state === FINAL_JORNADA)
    if (finalJornada) {
      state.fim = finalJornada.fim
    }
    return historico
  }
  state.historico = getValueAndParse(keyHistorico, [], cb)
}
