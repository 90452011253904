import {
  GET_USUARIO_MATRICULA,
  GET_USUARIO_NOME,
  GET_USUARIO_EMAIL,
  GET_CLIENT_ID,
  GET_TOKEN,
  GET_PHONE
} from './constants'

export default {
  [GET_USUARIO_MATRICULA]: ({ matricula }) => matricula,
  [GET_USUARIO_NOME]: ({ name }) => name,
  [GET_USUARIO_EMAIL]: ({ email }) => email,
  [GET_CLIENT_ID]: ({ client_id }) => client_id,
  [GET_TOKEN]: ({ token }) => token,
  [GET_PHONE]: ({phone}) => phone,
}
