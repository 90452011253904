import {
  KEY_STORE,
  SET_AUTH_TOKEN,
  SAVE_TOKEN,
  REMOVE_AUTH_TOKEN
} from './constants'

import { MODULE_NAME as JORNADA, CLEAR } from '@/store/jornada'

export default {
  [SET_AUTH_TOKEN]({ commit, dispatch }, _token) {
    commit(SET_AUTH_TOKEN, _token)
    dispatch(SAVE_TOKEN)
  },
  [SAVE_TOKEN]({ state }) {
    localStorage.setItem(KEY_STORE, state.token)
  },
  [REMOVE_AUTH_TOKEN]({ commit, dispatch }) {
    commit(REMOVE_AUTH_TOKEN)
    dispatch(`${JORNADA}/${CLEAR}`, null, { root: true })
    localStorage.removeItem(KEY_STORE)
  }
}
