export const MODULE_NAME = 'jornada'
export const KEY_STORE_JORNADA = 'jornada'
export const KEY_STORE_HISTORICO = 'historico'
export const KEY_STORE_SYNC = 'sincronizar'

export const SALT_JORNADA = '517da26bcdc3da342ddaf65ffe167936'
export const SALT_HISTORICO = '25b9b487ee5c35a1f9b0d64a793997a0'
export const SALT_SYNC = 'cde9da9c3d3dfc7a4b4ab0e7a4974651'

export const LOAD = 'LOAD'
export const INIT = 'INIT'
export const STOP = 'STOP'
export const CLEAR = 'CLEAR'

export const SAVE_JORNADA = 'SAVE_JORNADA'
export const SAVE_HISTORICO = 'SAVE_HISTORICO'
export const SAVE_SYNC = 'SAVE_SYNC'
export const SET_INTERVAL = 'SET_INTERVAL'
export const SET_INICIO = 'SET_INICIO'
export const SET_TEMPO_ATUAL = 'SET_TEMPO_ATUAL'
export const SET_HISTORICO = 'SET_HISTORICO'
export const SET_SYNC = 'SET_SYNC'

export const GET_TEMPO = 'GET_TEMPO'
export const GET_TIME_LOCALE = 'GET_TIME_LOCALE'
export const GET_STATE = 'GET_STATE'
export const GET_FINAL_JORNADA = 'GET_FINAL_JORNADA'
export const GET_JORNADA_INICIADA = 'GET_JORNADA_INICIADA'
export const GET_HISTORICO_REVERSO = 'GET_HISTORICO_REVERSO'
export const GET_SYNC = 'GET_SYNC'
export const GET_INICIO = 'GET_INICIO'

export const SET_INICIO_JORNADA = 'SET_INICIO_JORNADA'
export const SET_DIRECAO = 'SET_DIRECAO'
export const SET_ESPERA = 'SET_ESPERA'
export const SET_CARREGAMENTO = 'SET_CARREGAMENTO'
export const SET_DESCARREGAMENTO = 'SET_DESCARREGAMENTO'
export const SET_BALANCA = 'SET_BALANCA'
export const SET_FISCALIZACAO = 'SET_FISCALIZACAO'
export const SET_TRANSITO = 'SET_TRANSITO'
export const SET_ACIDENTE = 'SET_ACIDENTE'
export const SET_ABASTECIMENTO = 'SET_ABASTECIMENTO'
export const SET_MANUTENCAO = 'SET_MANUTENCAO'
export const SET_TROCA_PNEU = 'SET_TROCA_PNEU'
export const SET_OUTROS = 'SET_OUTROS'
export const SET_DESCANSO = 'SET_DESCANSO'
export const SET_PARADA_EMERGENCIAL = 'SET_PARADA_EMERGENCIAL'
export const SET_INTERVALO_REFEICAO = 'SET_INTERVALO_REFEICAO'
export const SET_INTERVALO_JORNADA = 'SET_INTERVALO_JORNADA'
export const SET_FINAL_JORNADA = 'SET_FINAL_JORNADA'
export const SYNC_JOURNEY = 'SYNC_JOURNEY'
export const SYNC_INTERVAL = 'SYNC_INTERVAL'
// export const SET_JOURNEY = 'SET_JOURNEY'

export const INICIO_JORNADA = 'INICIO_JORNADA'
export const DIRECAO = 'DIRECAO'
export const ESPERA = 'ESPERA'
export const PARADA_EMERGENCIAL = 'PARADA_EMERGENCIAL'
export const CARREGAMENTO = 'CARREGAMENTO'
export const DESCARREGAMENTO = 'DESCARREGAMENTO'
export const BALANCA = 'BALANCA'
export const FISCALIZACAO = 'FISCALIZACAO'
export const TRANSITO = 'TRANSITO'
export const ACIDENTE = 'ACIDENTE'
export const ABASTECIMENTO = 'ABASTECIMENTO'
export const MANUTENCAO = 'MANUTENCAO'
export const TROCA_PNEU = 'TROCA_PNEU'
export const OUTROS = 'OUTROS'
export const DESCANSO = 'DESCANSO'
export const INTERVALO_REFEICAO = 'INTERVALO_REFEICAO'
export const INTERVALO_JORNADA = 'INTERVALO_JORNADA'
export const FINAL_JORNADA = 'FINAL_JORNADA'

export const SET_DESCRIPTION = 'SET_DESCRIPTION'

export const STATES = {
  INICIO_JORNADA,
  DIRECAO,
  ESPERA,
  INTERVALO_REFEICAO,
  INTERVALO_JORNADA,
  FINAL_JORNADA,
  CARREGAMENTO,
  DESCARREGAMENTO,
  BALANCA,
  FISCALIZACAO,
  TRANSITO,
  ACIDENTE,
  ABASTECIMENTO,
  MANUTENCAO,
  TROCA_PNEU,
  OUTROS,
  DESCANSO,
  PARADA_EMERGENCIAL
}

export const DESCRICAO = {
  [INICIO_JORNADA]: 'Início da Jornada',
  [DIRECAO]: 'Direção',
  [ESPERA]: 'Espera',
  [INTERVALO_REFEICAO]: 'Intervalo de Refeição',
  [INTERVALO_JORNADA]: 'Intervalo de Jornada',
  [FINAL_JORNADA]: 'Final da Jornada',
  [CARREGAMENTO]: 'Carregamento',
  [DESCARREGAMENTO]: 'Descarregamento',
  [BALANCA]: 'Balança',
  [FISCALIZACAO]: 'Fiscalização',
  [TRANSITO]: 'Trânsito',
  [ACIDENTE]: 'Acidente',
  [ABASTECIMENTO]: 'Abastecimento',
  [MANUTENCAO]: 'Manutenção',
  [TROCA_PNEU]: 'Troca de Pneu',
  [OUTROS]: 'Outros2',
  [DESCANSO]: 'Descanso Obrigatório',
  [PARADA_EMERGENCIAL]: 'Parada Emergencial'
}
