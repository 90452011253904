<template>
  <v-app>
    <v-main style="background-color:rgb(36, 83, 120)">
      <!-- <router-view></router-view> -->
      <slot />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'EmptyLayout'
}
</script>
