import Vue from 'vue'
import Vuex from 'vuex'

import authModule, { MODULE_NAME as auth } from './auth'
import configModule, { MODULE_NAME as config } from './config'
import jornadaModule, { MODULE_NAME as jornada } from './jornada'
import vehiclesModule, { MODULE_NAME as vehicles } from './vehicles'
import enterpriseModule, { MODULE_NAME as enterprise } from './enterprise'
import timeControlModule, { MODULE_NAME as timeControl } from './timeControl'
import lunchTimeModule, { MODULE_NAME as lunchTime } from './lunchTimeControl'
import drivingTimeControl, {
  MODULE_NAME as drivingTime
} from './drivingTimeControl'
Vue.use(Vuex)

export default new Vuex.Store({
  // state: {
  // },
  // mutations: {
  // },
  // actions: {
  // },
  // getters: {
  // },
  modules: {
    [auth]: authModule,
    [config]: configModule,
    [jornada]: jornadaModule,
    [vehicles]: vehiclesModule,
    [enterprise]: enterpriseModule,
    [timeControl]: timeControlModule,
    [lunchTime]: lunchTimeModule,
    [drivingTime]: drivingTimeControl
  }
})
