import { http } from '@/services/http'
import {
  MODULE_NAME as AUTH,
  AUTH_TOKEN_KEY_NAME,
  SET_AUTH_TOKEN
} from '@/store/auth'
import store from '../store'
import Vue from 'vue'
import VueRouter from 'vue-router'
import loginView from '@/views/system/login.vue'
import emptyLayout from '@/layouts/empty.vue'

const defaultLayout = () =>
  import(
    /* webpackChunkName: "defaultLayout" */
    '@/layouts/default.vue'
  )

Vue.use(VueRouter)
Vue.component('emptyLayout', emptyLayout)
Vue.component('defaultLayout', defaultLayout)

// # VIEWS

const inicioView = () =>
  import(
    /* webpackChunkName: "inicioView" */
    '@/views/inicio.vue'
  )

const historicoView = () =>
  import(
    /* webpackChunkName: "historicoView" */
    '@/views/historico.vue'
  )
const empresaView = () =>
  import(
    /* webpackChunkName: "empresaView" */
    '@/views/empresa.vue'
  )

const systemConfiguracaoView = () =>
  import(
    /* webpackChunkName: "systemConfiguracaoView" */
    '@/views/system/configuracao.vue'
  )

// # ROUTER CONFIG

const routes = [
  {
    name: 'SistemaEntrar',
    path: '/sistema/entrar',
    alias: '/entrar',
    component: loginView,
    meta: { public: true, layout: 'empty' }
  },
  {
    name: 'Inicio',
    path: '/',
    alias: '/inicio',
    component: inicioView,
    meta: {
      breadcrumbs: [{ text: 'Registro de Ponto' }]
    }
  },
  {
    name: 'Historico',
    path: '/historico',
    component: historicoView,
    meta: {
      breadcrumbs: [{ text: 'Histórico' }]
    }
  },
  {
    name: 'SistemaConfiguracao',
    path: '/configuracao',
    component: systemConfiguracaoView,
    meta: {
      breadcrumbs: [{ text: 'Sistema' }, { text: 'Configuração' }]
    }
  },
  {
    name: 'Empresa',
    path: '/empresa',
    component: empresaView,
    meta: {
      breadcrumbs: [{ text: 'Localização da Empresa' }]
    }
  }
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

router.beforeEach((to, from, next) => {
  const isPublic = to.meta.public === true

  if (isPublic) {
    next()
  } else {
    // TODO validar o token
    // TODO verificar a validade do token
    // TODO se o token estiver no range de atualizar antes de vencer, atualizar o token
    const authToken = localStorage[AUTH_TOKEN_KEY_NAME]
    if (!authToken) {
      next({ name: 'SistemaEntrar', replace: true })
    } else {
      store.dispatch(`${AUTH}/${SET_AUTH_TOKEN}`, authToken)
      http.defaults.headers.common.Authorization = `Bearer ${authToken}`
      next(true)
    }
  }
})

export default router
