export const MODULE_NAME = 'config'
export const KEY_STORE = 'sysConfig'
export const SET_CONFIG = 'SET_CONFIG'
export const GET_MODOESCURO = 'GET_MODOESCURO'
export const GET_BOTAOREDONDO = 'GET_BOTAOREDONDO'

export const SET_ALERT = 'SET_ALERT'
export const ALERT = 'ALERT'
export const SAVE = 'SAVE'

function getConfigState() {
  const SAVED_VALUE_CONFIG = localStorage.getItem(KEY_STORE)
  if (SAVED_VALUE_CONFIG) {
    return JSON.parse(SAVED_VALUE_CONFIG)
  }
  return {
    modoEscuro: false,
    botaoRedondo: false,
    alert: {
      show: false,
      error: false,
      message: '',
      syncing: false
    }
  }
}

export default {
  namespaced: true,
  state: getConfigState,
  mutations: {
    [SET_CONFIG](state, value) {
      state.modoEscuro = value.modoEscuro
      state.botaoRedondo = value.botaoRedondo
    },
    [SET_ALERT](state, value) {
      state.alert = { ...value }
    }
  },
  actions: {
    [SET_ALERT]({ commit }, value) {
      commit(SET_ALERT, value)
    },
    [SET_CONFIG]({ commit, dispatch }, { botaoRedondo, modoEscuro, alert }) {
      commit(SET_CONFIG, { botaoRedondo, modoEscuro, alert })
      dispatch(SAVE)
    },
    [SAVE]({ state }) {
      localStorage.setItem(KEY_STORE, JSON.stringify(state))
    }
  },
  getters: {
    [GET_MODOESCURO]: state => state.modoEscuro || false,
    [GET_BOTAOREDONDO]: state => state.botaoRedondo || false,
    [ALERT](state) {
      return state.alert
    }
  }
}
