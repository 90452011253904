<template>
  <v-text-field
    ref="email"
    type="email"
    counter="50"
    :value="value"
    :required="required"
    :id="id"
    :label="label"
    :prepend-icon="icons.mdiAccount"
    :rules="rules.email"
    :error="error || elError"
    :disabled="disabled"
    @keydown.enter="keyDownEnter"
    @blur="elBlur"
    @input="elInput"
  />
</template>

<script>
import Joi from 'joi'
import { mdiAccount } from '@mdi/js'
import { messages } from './joyMessages'

export default {
  name: 'elEmailField',
  props: {
    id: {
      type: String,
      default: 'email'
    },
    label: {
      type: String,
      default: 'Email'
    },
    value: {
      type: String
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    next: {
      type: String
    }
  },
  data() {
    return {
      icons: { mdiAccount },
      rules: {
        email: [
          value => {
            let schema = Joi.string().trim()
            if (this.required) {
              schema = schema.required()
            } else {
              schema = schema.allow('')
            }
            schema = schema.email({ tlds: false })
            const { error } = schema.validate(value, { messages })
            if (error) {
              return error.message
            }
            return true
          }
        ]
      },
      elError: false
    }
  },
  computed: {
    isValidEmail() {
      return this.rules.email.every(r => r(this.value) === true)
    }
  },
  methods: {
    elInput(value) {
      value = value.trim()
      this.$emit('input', value)
    },
    elBlur(event) {
      const value = event.target.value.trim()
      event.target.value = value
      if (value === '') {
        this.elError = this.require
      } else {
        this.elError = !this.isValidEmail
      }
    },
    keyDownEnter() {
      if (this.isValidEmail && this.next) {
        document.querySelector(`#${this.next}`).focus()
      }
    }
  }
}
</script>

<style></style>
