import whatsapp from '../services/whatsapp'

export const MODULE_NAME = 'timeControl'

export const SET_TIME_CONTROL = 'SET_TIME_CONTROL'
export const RESET_TIME_CONTROL = 'RESET_TIME_CONTROL'
export const GET_TIME_CONTROL = 'GET_TIME_CONTROL'

export const TIME_CONTROLLERS_KEY = 'timeControllers'

import { KEY as LUNCH_TIME } from './lunchTimeControl'

import {
  MODULE_NAME as AUTH,
  GET_PHONE,
  GET_USUARIO_NOME
} from './auth/constants'

import { MODULE_NAME as JOURNEY, GET_INICIO } from './jornada/constants'

export default {
  namespaced: true,
  state: {
    timeControl: {
      normal: {
        passed: false,
        notification: false,
        limit: 28800000 // 28800000 ms
      },
      extraHour: {
        passed: false,
        notification: false,
        limit: 36000000 //36000000 ms
      }
      // syndicate: {
      //   passed: false,
      //   notification: false,
      //   limit: 15000 //43200000 ms
      // }
    }
  },
  mutations: {
    [SET_TIME_CONTROL](state, timeControl) {
      state.timeControl = { ...timeControl }
    }
  },
  actions: {
    [RESET_TIME_CONTROL]({ commit, getters }) {
      const timeControl = { ...getters[GET_TIME_CONTROL] }
      const reset = {
        normal: {
          passed: false,
          notification: false,
          limit: 28800000 // 28800000 ms
        },
        extraHour: {
          passed: false,
          notification: false,
          limit: 36000000 //36000000 ms
        }
      }
      Object.assign(timeControl, reset)

      commit(SET_TIME_CONTROL, timeControl)
      localStorage.setItem(
        TIME_CONTROLLERS_KEY,
        JSON.stringify({ ...getters[GET_TIME_CONTROL] })
      )
    },

    //TODO - ENVIAR NOTIFICACAO PARA GESTOR VIA WHATSAPP
    async [SET_TIME_CONTROL]({ commit, getters, rootGetters }, time) {
      time = new Date(time).getTime()
      let lunchTime = JSON.parse(localStorage.getItem(LUNCH_TIME))
      let isLunching, lunchTotal
      if (lunchTime === null) {
        lunchTime = 0
      } else {
        isLunching = lunchTime.isLunching
        lunchTotal = lunchTime.total
      }
      let controllers = JSON.parse(localStorage.getItem(TIME_CONTROLLERS_KEY))
      const phone = rootGetters[`${AUTH}/${GET_PHONE}`]
      const start = new Date(rootGetters[`${JOURNEY}/${GET_INICIO}`]).getTime()
      const username = rootGetters[`${AUTH}/${GET_USUARIO_NOME}`]

      if (controllers === null) {
        localStorage.setItem(
          TIME_CONTROLLERS_KEY,
          JSON.stringify({ ...getters[GET_TIME_CONTROL] })
        )
        controllers = JSON.parse(localStorage.getItem(TIME_CONTROLLERS_KEY))
      }

      const { normal, extraHour } = { ...controllers }
      // const syndicateTime = syndicate.limit - lunchTotal
      const extraHourTime = extraHour.limit - lunchTotal
      const normalTime = normal.limit - lunchTotal

      if (!normal.passed && time > normalTime) {
        normal.passed = true
        if (normal.passed && !normal.notification && !isLunching) {
          try {
            const _date = new Date(
              start + normalTime + lunchTotal
            ).toLocaleString()
            if (phone && navigator.onLine) {
              // await whatsapp.send({
              //   phone: phone,
              //   message: `*DIÁRIO DE BORDO 🚨*\n——————————————\nUSUÁRIO: *${username}*\nDATA: *${_date}*\n*TEMPO DE JORNADA EXCEDIDO:* 8 horas`
              // })
              normal.notification = true
            }
          } catch (error) {
            normal.notification = false
          }
        }
      }

      if (!extraHour.passed && time > extraHourTime) {
        extraHour.passed = true
        if (extraHour.passed && !extraHour.notification && !isLunching) {
          try {
            const _date = new Date(
              start + extraHourTime + lunchTotal
            ).toLocaleString()
            if(phone && navigator.onLine){
              // await whatsapp.send({
              //   phone: phone,
              //   message: `*DIÁRIO DE BORDO 🚨*\n——————————————
              //     \nUSUÁRIO: *${username}*\nDATA: *${_date}*\n*TEMPO DE JORNADA EXCEDIDO:* 10 horas`
              // })
              extraHour.notification = true
            }
          } catch (error) {
            extraHour.notification = false
          }
        }
      }
      Object.assign(controllers, { normal, extraHour })
      commit(SET_TIME_CONTROL, controllers)
      localStorage.setItem(
        TIME_CONTROLLERS_KEY,
        JSON.stringify({ ...getters[GET_TIME_CONTROL] })
      )
      return extraHour.passed ? 'extra' : normal.passed ? 'normal' : ''
    }
  },
  getters: {
    [GET_TIME_CONTROL]({ timeControl }) {
      return timeControl
    }
  }
}
