import {  SET_SYNC, SAVE_SYNC, GET_TIME_LOCALE } from '../constants'

import {
  MODULE_NAME as AUTH,
  GET_USUARIO_MATRICULA,
  GET_USUARIO_NOME,
  GET_USUARIO_EMAIL
} from '@/store/auth/constants'

const auth = getterName => `${AUTH}/${getterName}`

export function setSync({ state, commit, dispatch, getters, rootGetters }) {
  const matricula = rootGetters[auth(GET_USUARIO_MATRICULA)]
  const nome = rootGetters[auth(GET_USUARIO_NOME)]
  const email = rootGetters[auth(GET_USUARIO_EMAIL)]
  const tempo = getters[GET_TIME_LOCALE]
  commit(SET_SYNC, {
    idx: state.sync.length + 1,
    data: state.inicio,
    motorista: { matricula, nome, email },
    tempo
  })
  dispatch(SAVE_SYNC)
}
