import axios from 'axios'
const { VUE_APP_API } = process.env
require('dotenv').config()

const axiosConfig = {
  baseURL: VUE_APP_API
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
}

export const http = axios.create(axiosConfig)
