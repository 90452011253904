export default {
  interval: null,
  inicio: null,
  fim: null,
  jornada: {
    state: 'NAO_INICIADO',
    descricao: 'Jornada não iniciada',
    inicio: null,
    fim: null,
    synced: false
  },
  historico: [],
  sync: [],
  description: null
}
