import { intervalToDuration } from 'date-fns'
// import { getEmail } from '@/store/jornada/util'
import { getKeySync } from '@/store/jornada/util'
import { DeviceUUID } from 'device-uuid'

// export function Sync({ http, store, router }) {
export function Sync({ http, store }) {
  this.$store = store

  // Object.assign(this, mapGetters(AUTH, [GET_USUARIO_EMAIL]))

  this.synchronize = async function() {
    const keySync = getKeySync(this.$store.getters)
    let value = localStorage.getItem(keySync)

    if (!value || !value.length) {
      return
    }
    const items = JSON.parse(value).filter(i => i.synced === false)
    for (let i = 0; i < items.length; i++) {
      items[i] = {
        ...items[i],
        historico: items[i].historico.map(item => {
          // ? Calcular a duração do intervalo
          const interval = intervalToDuration({
            start: new Date(item.fim),
            end: new Date(item.inicio)
          })

          if (Date.now() < item.inicio) {
            alert('Data inicial não pode estar no futuro!')
            return
          }
          if (Date.now() < item.fim) {
            alert('Data inicial não pode estar no futuro!')
            return
          }

          const getSeconds = `0${interval.seconds}`.slice(-2)
          const getMinutes = `0${interval.minutes}`.slice(-2)
          const getHours = `0${interval.hours}`.slice(-2)

          const tempo = `${getHours}:${getMinutes}:${getSeconds}`
          return {
            ...item,
            device: new DeviceUUID().get(),
            tempo,
            synced: true
          }
        })
      }

      const result = await http.post('/journey/sync', items[i])
      items[i].synced = Number(result.data.id) > 0
    }
    localStorage.setItem(keySync, JSON.stringify(items))
    this.$store.dispatch('jornada/LOAD', null, { root: true })
  }
}
