export const MODULE_NAME = 'enterprise'
export const FETCH_ENTERPRISE = 'FETCH_ENTERPRISE'
export const SET_ENTERPRISE = 'SET_ENTERPRISE'
export const GET_ENTERPRISE = 'GET_ENTERPRISE'
export const KEY = 'enterprise'
import { http } from '../services/http'

export default {
  namespaced: true,
  state: {
    enterprise: {}
  },
  mutations: {
    [SET_ENTERPRISE](state, enterprise) {
      state.enterprise = enterprise
    }
  },
  actions: {
    async [FETCH_ENTERPRISE]({ commit }) {
      const { data } = await http.get('/geometries/enterprise')
      localStorage.setItem(KEY, JSON.stringify(data))
      commit(SET_ENTERPRISE, data)
    }
  },
  getters: {
    [GET_ENTERPRISE](state) {
      return state.enterprise
    }
  }
}
