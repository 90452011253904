import {
  GET_TEMPO,
  GET_STATE,
  GET_JORNADA_INICIADA,
  GET_HISTORICO_REVERSO,
  GET_SYNC,
  GET_TIME_LOCALE,
  GET_INICIO
} from './constants'

export default {
  [GET_INICIO]({inicio}){
    return inicio
  },
  [GET_TEMPO]({ jornada, inicio }) {
    // TODO Max 5 horas dirigindo
    if (jornada.fim === null || jornada.fim === undefined) {
      return '00:00:00'
    }
    const diff = jornada.fim - inicio
    return diff
  },
  [GET_TIME_LOCALE]({ jornada, inicio }) {
    if (jornada.fim === null || jornada.fim === undefined) {
      return '00:00:00'
    }
    const diff = new Date(0, 0, 0, 0, 0, 0, jornada.fim - inicio)
    return diff.toLocaleTimeString()
  },
  [GET_STATE]: ({ jornada }) => jornada.state,
  [GET_JORNADA_INICIADA]({ inicio, fim }) {
    return inicio !== null && fim === null
  },
  [GET_HISTORICO_REVERSO]({ historico }) {
    const _historico = Array.from(historico)
    return _historico.sort((a, b) => b.idx - a.idx)
  },
  [GET_SYNC]: ({ sync }) => {
    return sync.map(item => ({
      ...item,
      quantidade_historico: item.historico.length || 0,
    }))
  }
}
