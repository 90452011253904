import { DESCRICAO, DIRECAO, OUTROS } from '../constants'
import { KEY, KEY_STORE } from '../../vehicles/constants'

function returnDescription(state, value) {
  if (value === DIRECAO) {
    const vehicles = JSON.parse(localStorage.getItem(KEY))
    const vehicleId = JSON.parse(localStorage.getItem(KEY_STORE))
    const plate = vehicles.find(vehicle => vehicle.id === vehicleId).plate

    return `${DESCRICAO[value]} - Veículo: ${plate}`
  }
  if (value === OUTROS) {
    return state.description
  }
  return DESCRICAO[value]
}

export const alterarSituacaoJornada = value => (state, geolocation) => {
  state.jornada.state = value
  state.jornada.synced = false
  state.jornada.descricao = returnDescription(state, value)
  state.jornada.inicio = state.jornada.fim
  state.jornada.location = geolocation
}
