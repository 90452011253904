export function getLocation() {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject)
  }).then(resolved, rejected)
}
function resolved(result) {
  return { allowed: true, result }
}

function rejected(result) {
  return { allowed: false, result }
}
