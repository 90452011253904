export const MODULE_NAME = 'lunchTime'

export const GET_LUNCH_TIME = 'GET_LUNCH_TIME' //                         ok |
export const GET_LUNCH_TIME_DURATION = 'GET_LUNCH_TIME_DURATION' //       ok |
export const START_LUNCH_TIME = 'START_LUNCH_TIME' //                     ok |
export const FINISH_LUNCH_TIME = 'FINISH_LUNCH_TIME' //                   ok |
export const SET_LUNCH_TIME = 'SET_LUNCH_TIME' //                         ok |
export const RESET_LUNCH_TIME = 'RESET_LUNCH_TIME' //                     ok |
export const KEY = 'lunchTime'

export default {
  namespaced: true,
  state: {
    lunchTime: {
      isLunching: false,
      start: null,
      end: null,
      total: 0
    }
  },
  mutations: {
    [SET_LUNCH_TIME]: (state, lunchTime) => (state.lunchTime = lunchTime)
  },
  actions: {
    [START_LUNCH_TIME]: ({ commit, dispatch, getters }) => {
      let lunchTime = JSON.parse(localStorage.getItem(KEY))
      if (lunchTime === null) {
        localStorage.setItem(
          KEY,
          JSON.stringify({ ...getters[GET_LUNCH_TIME] })
        )
        lunchTime = JSON.parse(localStorage.getItem(KEY))
      }
      if (!lunchTime.isLunching) {
        lunchTime.start = Date.now()
        lunchTime.isLunching = true
        commit(SET_LUNCH_TIME, lunchTime)
        localStorage.setItem(
          KEY,
          JSON.stringify({ ...getters[GET_LUNCH_TIME] })
        )
      } else {
        dispatch(FINISH_LUNCH_TIME, lunchTime)
      }
    },
    [FINISH_LUNCH_TIME]: ({ commit, getters }, lunchTime) => {
      if (lunchTime.isLunching && !lunchTime.end) {
        lunchTime.end = Date.now()
        lunchTime.isLunching = false
        lunchTime.total = lunchTime.total + lunchTime.end - lunchTime.start
        lunchTime.start = null
        lunchTime.end = null
        commit(SET_LUNCH_TIME, lunchTime)
        localStorage.setItem(
          KEY,
          JSON.stringify({ ...getters[GET_LUNCH_TIME] })
        )
      }
    },
    [RESET_LUNCH_TIME]: ({ commit, getters }) => {
      const lunchTime = {
        isLunching: false,
        start: null,
        end: null,
        total: 0
      }
      commit(SET_LUNCH_TIME, lunchTime)
      localStorage.setItem(KEY, JSON.stringify({ ...getters[GET_LUNCH_TIME] }))
    }
  },
  getters: {
    [GET_LUNCH_TIME]: ({ lunchTime }) => lunchTime,
    [GET_LUNCH_TIME_DURATION]: ({ lunchTime }) => lunchTime.total
  }
}
