export const MODULE_NAME = 'auth'
export const KEY_STORE = 'authToken'
export const AUTH_TOKEN_KEY_NAME = 'authToken'

export const GET_USUARIO_MATRICULA = 'GET_USUARIO_MATRICULA'
export const GET_USUARIO_NOME = 'GET_USUARIO_NOME'
export const GET_USUARIO_EMAIL = 'GET_USUARIO_EMAIL'
export const GET_CLIENT_ID = 'GET_CLIENT_ID'
export const GET_TOKEN = 'GET_TOKEN'
export const  GET_PHONE = 'GET_PHONE' 

export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN'
export const SAVE_TOKEN = 'SAVE_TOKEN'

export const REMOVE_AUTH_TOKEN = 'REMOVE_AUTH_TOKEN'
