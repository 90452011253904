import router from '@/router'
import { getLocation } from './geolocation1'
import { http } from './http'
import { Login } from './login'
import { Sync } from './sync'
import { Geometry } from './geometry'
import { Vehicles } from './vehicles'
import { RealTime } from './synchronize'
// import { Journey } from './getActualJourney'

export const GeolocationService = { getLocation }
export const LoginService = store =>
  new Login({ http, store, router, services })
export const SyncService = store => new Sync({ http, store, router, services })
export const GeometryService = store => new Geometry({ http, store, router })
export const VehiclesService = store => new Vehicles({ http, store, router })
export const RealTimeService = store => new RealTime({ http, store, router })
// export const JourneyService = store => new Journey({ http, store, router })

const services = {
  GeolocationService,
  LoginService,
  SyncService,
  GeometryService,
  VehiclesService,
  RealTimeService,
  // JourneyService
}
