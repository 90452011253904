<template>
  <component :key="layout" :is="layout" v-cloak>
    <router-view :key="$route.path" />
  </component>
</template>

<script>
import { MODULE_NAME as CONFIG, GET_MODOESCURO } from '@/store/config.js'

export default {
  name: 'App',
  watch: {
    [GET_MODOESCURO](value) {
      this.$vuetify.theme.dark = value
    }
  },
  computed: {
    [GET_MODOESCURO]() {
      return this.$store.getters[`${CONFIG}/${GET_MODOESCURO}`]
    },
    layout() {
      let layoutName = 'default'
      if (this.$route.meta.layout) {
        layoutName = this.$route.meta.layout
      }
      return layoutName + 'Layout'
    }
  },
  mounted() {
    this.$vuetify.theme.dark = this[GET_MODOESCURO]
  }
}
</script>

<style>
[v-cloak] {
  display: none;
}
</style>
