import {
  LOAD,
  CLEAR,
  SET_INTERVAL,
  SET_INICIO,
  SET_TEMPO_ATUAL,
  SET_HISTORICO,
  SET_SYNC,
  SET_INICIO_JORNADA,
  SET_DIRECAO,
  SET_ESPERA,
  SET_INTERVALO_REFEICAO,
  SET_INTERVALO_JORNADA,
  SET_FINAL_JORNADA,
  DIRECAO,
  ESPERA,
  INTERVALO_REFEICAO,
  INTERVALO_JORNADA,
  SET_CARREGAMENTO,
  SET_DESCARREGAMENTO,
  SET_BALANCA,
  SET_FISCALIZACAO,
  SET_TRANSITO,
  SET_ACIDENTE,
  SET_ABASTECIMENTO,
  SET_MANUTENCAO,
  SET_TROCA_PNEU,
  SET_OUTROS,
  SET_DESCANSO,
  CARREGAMENTO,
  DESCARREGAMENTO,
  BALANCA,
  FISCALIZACAO,
  TRANSITO,
  ACIDENTE,
  ABASTECIMENTO,
  MANUTENCAO,
  TROCA_PNEU,
  OUTROS,
  DESCANSO,
  SET_PARADA_EMERGENCIAL,
  PARADA_EMERGENCIAL,
  SET_DESCRIPTION,
  SYNC_JOURNEY,
  SAVE_SYNC,
  MODULE_NAME,
  SYNC_INTERVAL,
  SAVE_JORNADA,
  SAVE_HISTORICO
} from '../constants'

import {
  jornadaLoad,
  clear,
  setTempoAtual,
  setInicioJornada,
  setFinalJornada,
  setHistorico,
  setSync,
  alterarSituacaoJornada
} from './functions'

export default {
  [LOAD]: jornadaLoad,
  [SYNC_JOURNEY](state, { dispatch, online, journeyId }) {
    if (online) {
      const lastJourney = state.sync[state.sync.length - 1]
      lastJourney.synced = true
      lastJourney.journeyId = journeyId
      dispatch(`${MODULE_NAME}/${SAVE_SYNC}`)
    } else {
      const lastJourney = state.sync[state.sync.length - 1]
      lastJourney.journeyId = journeyId
      dispatch(`${MODULE_NAME}/${SAVE_SYNC}`)
    }
  },
  [SYNC_INTERVAL](data, { dispatch, online, journeyId, intervalToSync }) {
    if (online && !intervalToSync) {
      const actualInterval = data.jornada
      actualInterval.synced = true
      const actualIntervalHistoric = data.historico.find(
        item => item.state === actualInterval.state
      )
      actualIntervalHistoric.synced = true
      actualIntervalHistoric.journeyId = journeyId
    }

    if (!online && !intervalToSync) {
      const actualInterval = data.jornada
      actualInterval.synced = true
      const actualIntervalHistoric = data.historico.find(
        item => item.state === actualInterval.state
      )
      actualIntervalHistoric.journeyId = journeyId
      actualIntervalHistoric.synced = false
    }

    if (intervalToSync) {
      data.historico = data.historico.map(item => {
        if (item.state === intervalToSync) {
          item.synced = true
        }
        return item
      })
    }
    dispatch(`${MODULE_NAME}/${SAVE_JORNADA}`)
    dispatch(`${MODULE_NAME}/${SAVE_HISTORICO}`)
  },
  [SET_INTERVAL](state, _interval) {
    state.interval = _interval
  },
  [SET_INICIO](state, value) {
    state.inicio = value
  },
  [SET_DESCRIPTION](state, value) {
    state.description = value
  },
  [SET_TEMPO_ATUAL]: setTempoAtual,
  [SET_HISTORICO]: setHistorico,
  [SET_SYNC]: setSync,
  [SET_INICIO_JORNADA]: setInicioJornada,
  [SET_DIRECAO]: alterarSituacaoJornada(DIRECAO),
  [SET_ESPERA]: alterarSituacaoJornada(ESPERA),
  [SET_INTERVALO_REFEICAO]: alterarSituacaoJornada(INTERVALO_REFEICAO),
  [SET_INTERVALO_JORNADA]: alterarSituacaoJornada(INTERVALO_JORNADA),
  [SET_CARREGAMENTO]: alterarSituacaoJornada(CARREGAMENTO),
  [SET_DESCARREGAMENTO]: alterarSituacaoJornada(DESCARREGAMENTO),
  [SET_BALANCA]: alterarSituacaoJornada(BALANCA),
  [SET_FISCALIZACAO]: alterarSituacaoJornada(FISCALIZACAO),
  [SET_TRANSITO]: alterarSituacaoJornada(TRANSITO),
  [SET_ACIDENTE]: alterarSituacaoJornada(ACIDENTE),
  [SET_ABASTECIMENTO]: alterarSituacaoJornada(ABASTECIMENTO),
  [SET_MANUTENCAO]: alterarSituacaoJornada(MANUTENCAO),
  [SET_TROCA_PNEU]: alterarSituacaoJornada(TROCA_PNEU),
  [SET_OUTROS]: alterarSituacaoJornada(OUTROS),
  [SET_DESCANSO]: alterarSituacaoJornada(DESCANSO),
  [SET_PARADA_EMERGENCIAL]: alterarSituacaoJornada(PARADA_EMERGENCIAL),
  [SET_FINAL_JORNADA]: setFinalJornada,
  [CLEAR]: clear
}
