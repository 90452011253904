import hasha from 'hasha'

import { MODULE_NAME as AUTH, GET_USUARIO_EMAIL } from '@/store/auth'
import {
  KEY_STORE_JORNADA,
  KEY_STORE_HISTORICO,
  KEY_STORE_SYNC,
  SALT_JORNADA,
  SALT_HISTORICO,
  SALT_SYNC
} from './constants'

export const getEmail = rootGetter => rootGetter[`${AUTH}/${GET_USUARIO_EMAIL}`]

const makeSecretKey = (rootGetter, key, salt) => {
  let value = `${key}.${salt}.`
  if (typeof rootGetter === 'string') {
    value += rootGetter
  } else {
    value += getEmail(rootGetter)
  }
  // console.log('VALUE TO HASHG', value)
  // console.log('HASHED VALUE', hasha(value, { algorithm: 'md5' }))
  return hasha(value, { algorithm: 'md5' })
}

export const getKeyJornada = getter =>
  makeSecretKey(getter, KEY_STORE_JORNADA, SALT_JORNADA)

export const getKeyHistorico = getter =>
  makeSecretKey(getter, KEY_STORE_HISTORICO, SALT_HISTORICO)

export const getKeySync = getter =>
  makeSecretKey(getter, KEY_STORE_SYNC, SALT_SYNC)
