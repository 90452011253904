export function clear(state) {
  state.inicio = null
  state.fim = null
  state.jornada.state = null
  state.jornada.descricao = null
  state.jornada.inicio = null
  state.jornada.fim = null
  state.jornada.synced = false
  state.jornada.journeyId = null
  state.historico = []
  state.sync = []
}
