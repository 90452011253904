<template>
  <div class="d-flex flex-column align-center justify">
    <v-img
      class=" mb-6"
      max-height="200"
      contain
      src="@/assets/geoLogo.svg"
      v-if="GET_MODOESCURO"
    />
    <v-img
      class=" mb-6"
      max-height="120"
      contain
      src="@/assets/geoLogoColor.png"
      v-else
    />
    <!-- src="@/assets/el-geo-logo-novo-nobg.png" -->
    <!-- Georastreamento -->
    <span class="display-1 font-weight-light">
      Georastreamento
    </span>
    <!-- Inteligência em -->
    <span class="display-1 font-weight-light">
      Inteligência em
    </span>
    <!-- Mobilidade -->
    <span class="display-1 font-weight-light">
      Mobilidade
    </span>
    <!-- Titulo Versao -->
    <span class="mt-4 headline font-weight-thin">
      Versão
    </span>
    <!-- Valor Versao -->
    <span
      class="headline font-weight-thin mb-12 pb-12"
      style="cursor:pointer"
      @click.stop="verDataInicial"
    >
      <!-- o valor da versao fica no arquivo package.json -->
      <!-- na pasta rais do projeto -->
      {{ versao }}
      <!-- Y.MMD.DHHmm -->
      <!-- Y ano (utlimo numero 2021 -> 1) -->
      <!-- M mes (11 -> 11)-->
      <!-- D dia (02 -> 0.2)-->
      <!-- H hora (10 -> 10)-->
      <!-- m minutos (08 -> 08)-->
      <!-- 1.110.21008 -->
    </span>
  </div>
</template>

<script>
import { config } from '@/../package.json'

import { MODULE_NAME as CONFIG, GET_MODOESCURO } from '@/store/config.js'
export default {
  name: 'elAbout',
  data() {
    return {
      versao: config.versao
    }
  },
  computed: {
    [GET_MODOESCURO]() {
      return this.$store.getters[`${CONFIG}/${GET_MODOESCURO}`]
    }
  }
}
</script>

<style></style>
