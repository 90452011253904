import { VEHICLES, VEHICLE, KEY_STORE, KEY } from './constants'
export default {
  [VEHICLES](state) {
    return state.vehicles?.length
      ? state.vehicles
      : JSON.parse(localStorage.getItem(KEY))
  },
  [VEHICLE](state) {
    localStorage.setItem(KEY_STORE, state.vehicle)
    return state.vehicle
  }
}
