<template>
  <div>
    <v-text-field
      ref="cpf"
      type="text"
      v-mask="mask"
      :value="value"
      :required="required"
      :id="id"
      :label="label"
      :prepend-icon="icons.mdiAccount"
      :rules="rules"
      :error="error || elError"
      :disabled="disabled"
      @keydown.enter="keyDownEnter"
      @blur="elBlur"
      @input="elInput"
    />
  </div>
</template>

<script>
import { mdiAccount } from '@mdi/js'
import { isValidCPF } from '@brazilian-utils/brazilian-utils'

export default {
  name: 'ElCPF',
  props: {
    id: {
      type: String,
      default: 'cpf'
    },
    label: {
      type: String,
      default: 'CPF'
    },
    value: {
      type: String
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    next: {
      type: String
    }
  },
  data() {
    return {
      icons: { mdiAccount },
      mask: '###.###.###-##',
      rules: [
        v => !!v || 'Campo Obrigatório!',
        v => isValidCPF(v) || 'Insira um CPF válido!'
      ],
      elError: false
    }
  },
  methods: {
    elInput(value) {
      this.$emit('input', value)
    },
    elBlur(event) {
      const value = event.target.value.trim()
      if (value === '') {
        this.elError = this.require
      } else {
        this.elError = !isValidCPF(this.value)
      }
      this.$emit('input', '')
      this.$nextTick(() => this.elInput(value))
    },
    keyDownEnter() {
      if (this.value && isValidCPF(this.value)) {
        document.querySelector(`#${this.next}`).focus()
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.value) {
        this.elInput(this.value)
      }
    })
  }
}
</script>
