import { DESCRICAO, INICIO_JORNADA } from '../constants'

export function setInicioJornada(state, geolocation) {
  state.inicio = Date.now()
  state.fim = null
  state.jornada.state = INICIO_JORNADA
  state.jornada.descricao = DESCRICAO[INICIO_JORNADA]
  state.jornada.inicio = state.inicio
  state.jornada.fim = state.inicio
  state.jornada.location = geolocation
  state.historico = []
}
