import { mapActions } from 'vuex'
import {
  MODULE_NAME as AUTH,
  SET_AUTH_TOKEN,
  REMOVE_AUTH_TOKEN
} from '@/store/auth'

import { VehiclesService } from '../services'

export function Login({ http, store, router }) {
  this.$store = store
  const Vehicles = VehiclesService(this.$store)
  Object.assign(this, mapActions(AUTH, [SET_AUTH_TOKEN, REMOVE_AUTH_TOKEN]))

  this.login = async function Login(cpf, password) {
    const token = await this.validarCredencial(cpf, password)
    this[SET_AUTH_TOKEN](
      token
      // 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjcGYiOiIxMTAuMzI0LjcwNy03MSIsInBhc3N3b3JkIjoiMTEyM3Fxd2UifQ.trOsOIUCWup3MBwPMB06sDCozpirDlZgxaC2OELuxOw'
    )
    await Vehicles.getVehicles()
    // http.defaults.headers.common.Authorization = token
    router.push({ name: 'Inicio' })
  }

  this.validarCredencial = async function ValidarCredencial(cpf, password) {
    const token = await http
      .post('/system/login', {
        cpf,
        password
      })
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        if (err.message === 'Network Error') {
          throw new Error('Erro de rede')
        } else if (err.response.status === 401) {
          throw new Error('credencial inválida')
        } else {
          throw err
        }
      })
    return token
  }

  this.removeAuthToken = function() {
    this[REMOVE_AUTH_TOKEN]()
  }
}
