import jwt from 'jsonwebtoken'
import { SET_AUTH_TOKEN, REMOVE_AUTH_TOKEN } from './constants'

export default {
  [SET_AUTH_TOKEN](state, _token) {
    state.token = _token
    const tokenData = jwt.decode(_token) || { roles: [] }
    state.matricula = tokenData.matricula
    state.name = tokenData.name
    state.email = tokenData.email
    state.client_id = tokenData.client_id
    state.roles = tokenData.roles
    state.phone = tokenData.phone
  },
  [REMOVE_AUTH_TOKEN](state) {
    state.token = null
    state.matricula = null
    state.name = null
    state.email = null
    state.client_id = null
    state.roles = []
    state.phone = null
  }
}
