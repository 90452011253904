import whatsapp from '../services/whatsapp'

import {
  MODULE_NAME as AUTH,
  GET_PHONE,
  GET_USUARIO_NOME
} from './auth/constants'

export const MODULE_NAME = 'drivingTime'
export const KEY = MODULE_NAME

export const GET_DRIVING_TIME = 'GET_DRIVING_TIME'
export const SET_DRIVING_TIME = 'SET_DRIVING_TIME'
export const RESET_DRIVING_TIME = 'RESET_DRIVING_TIME'
export const START_DRIVING_TIME = 'START_DRIVING_TIME'
export const RESTART_DRIVING_TIME = 'RESTART_DRIVING_TIME'
export const IS_DRVING = 'IS_DRVING'

export default {
  namespaced: true,
  state: {
    drivingTime: {
      isDriving: false,
      start: null,
      stopwatch: 0,
      notification: false
    }
  },
  mutations: {
    [SET_DRIVING_TIME]: (state, drivingTime) =>
      (state.drivingTime = drivingTime)
  },
  actions: {
    [SET_DRIVING_TIME]: async ({ commit, getters, rootGetters }) => {
      let control = JSON.parse(localStorage.getItem(KEY))
      if (control === null) {
        control = { ...getters[GET_DRIVING_TIME] }
      }
      if (control.isDriving) {
        control.stopwatch = Date.now()
      }
      if (
        control.stopwatch - control.start > 19800000 /* 19800000 === 5.5h*/ &&
        !control.notification
      ) {
        const username = rootGetters[`${AUTH}/${GET_USUARIO_NOME}`]
        const phone = rootGetters[`${AUTH}/${GET_PHONE}`]
        const _date = new Date(control.stopwatch).toLocaleString()
        try{
          // await whatsapp.send({
          //   phone: phone,
          //   message: `*DIÁRIO DE BORDO 🚨*\n——————————————\nUSUÁRIO: *${username}*\nDATA: *${_date}*\n*TEMPO DE DIREÇÃO EXCEDIDO:* 5 horas e 30 minutos`
          // })
          control.notification = true
        }catch(error){
          control.notification = false
        }
      }
      commit(SET_DRIVING_TIME, control)
      localStorage.setItem(KEY, JSON.stringify(control))
    },
    [START_DRIVING_TIME]: ({ commit, getters }) => {
      const control = { ...getters[GET_DRIVING_TIME] }
      control.isDriving = true
      control.start = Date.now()
      commit(SET_DRIVING_TIME, control)
      localStorage.setItem(KEY, JSON.stringify(control))
    },
    [RESTART_DRIVING_TIME]: ({ commit }) => {
      const control = {
        isDriving: false,
        start: null,
        stopwatch: 0,
        notification: false
      }
      commit(SET_DRIVING_TIME, control)
      localStorage.setItem(KEY, JSON.stringify(control))
    }
  },
  getters: {
    [GET_DRIVING_TIME]: ({ drivingTime }) => drivingTime,
    [IS_DRVING]: ({ drivingTime }) => drivingTime.isDriving
  }
}
