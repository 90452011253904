<template>
  <div>
    <v-card-title>
      <h1 class="display-1">{{ title }}</h1>
    </v-card-title>
    <v-card-subtitle v-if="subtitle">{{ subtitle }}</v-card-subtitle>
  </div>
</template>

<script>
export default {
  name: 'elTitle',
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: false
    }
  }
}
</script>

<style></style>
