// import jwt from 'jsonwebtoken'
import { KEY_STORE } from './constants'

const token = localStorage.getItem(KEY_STORE)
// const tokenData = jwt.decode(token) || { roles: [] }

export default function getAuthState() {
  return {
    token,
    matricula: null,
    name: null,
    email: null,
    roles: null,
    client_id: null,
    phone: null
  }
}
