/*eslint-disable */
import {
  FINAL_JORNADA,
  INICIO_JORNADA,
  MODULE_NAME,
  SYNC_INTERVAL,
  SYNC_JOURNEY
} from '../store/jornada/constants'
import { DeviceUUID } from 'device-uuid'

function setIntervalSynced({
  commit,
  dispatch,
  journeyId,
  result,
  intervalToSync
}) {
  const online = !!result?.data?.id

  if (intervalToSync === FINAL_JORNADA) {
    commit(`${MODULE_NAME}/${SYNC_JOURNEY}`, {
      dispatch,
      journeyId,
      online
    })
  } else {
    commit(`${MODULE_NAME}/${SYNC_INTERVAL}`, {
      dispatch,
      online,
      journeyId,
      intervalToSync: online ? intervalToSync : undefined
    })
  }
}

function setStartJourneySynced({ commit, dispatch, result }) {
  const journeyId = result?.data?.id
  localStorage.setItem('journeyId', journeyId)
  commit(`${MODULE_NAME}/${SYNC_INTERVAL}`, {
    dispatch,
    online: !!journeyId,
    journeyId: journeyId || undefined
  })
}

function formatIntervalValue(interval) {
  return {
    ...interval,
    tempo: null,
    fim: interval.state === FINAL_JORNADA ? interval.fim : null,
    device: new DeviceUUID().get()
  }
}

function generateBaseJourneyValue({ driverId, interval }) {
  return {
    driverId,
    historic: [
      {
        ...interval,
        device: new DeviceUUID().get(),
        tempo: null,
        fim: null
      }
    ]
  }
}

async function updateJourneyHistoric(data, journeyId, http) {
  try {
    const result = await http.post('/journey/update', {
      journeyId,
      actualInterval: Array.isArray(data) ? undefined : data,
      intervalsToSynchronize: Array.isArray(data) ? data : undefined
    })
    return result
  } catch (error) {
    console.error(error)
  }
}

async function createJourney(journey, http) {
  try {
    const result = await http.post('/journey/create', journey)
    return result
  } catch (error) {
    console.error(error)
  }
}
export class RealTime {
  constructor({ http, store }) {
    this.synchronize = async function(interval, driverId, notSyncedJourneyId) {
      const { dispatch, commit } = store
      if (!Array.isArray(interval)) {
        if (interval.state === INICIO_JORNADA) {
          /**
           * Caso o intervalo seja de ínicio da jornada,
           * é gerado um objeto com os dados base da jornada e é cadastrado no banco.
           */
          const _journey = generateBaseJourneyValue({ driverId, interval })

          const result = await createJourney(_journey, http)

          setStartJourneySynced({ result, commit, dispatch })
        } else {
          /**
           * Caso seja qualquer outro intervalo,
           * é realizada a tentativa de atualizar o historico da jornada anteriormente criada.
           */
          const actualInterval = formatIntervalValue(interval)
          const journeyId =
            notSyncedJourneyId || localStorage.getItem('journeyId')
          const intervalToSync = interval.state

          const result = await updateJourneyHistoric(
            actualInterval,
            journeyId,
            http
          )

          setIntervalSynced({
            commit,
            dispatch,
            intervalToSync,
            journeyId,
            result
          })
        }
      } else {
        /*
         * Se o parâmetro 'interval' for um Array,
         *  cada índice será formatado e
         *  todos eles serão enviados
         *  ao backend para atualizar o historico de jornada de uma só vez.
         */

        if (interval[0].state === INICIO_JORNADA) {
          const journey = generateBaseJourneyValue({
            driverId,
            interval: interval[0]
          })
          const result = await createJourney(journey, http)
          setStartJourneySynced({ result, commit, dispatch })
        }

        const journeyId = notSyncedJourneyId
          ? notSyncedJourneyId
          : localStorage.getItem('journeyId')

        const intervalsToSynchronize = interval
          .filter(interval => interval.state !== INICIO_JORNADA)
          .map(interval => ({
            ...formatIntervalValue(interval)
          }))

        const result = await updateJourneyHistoric(
          intervalsToSynchronize,
          journeyId,
          http
        )

        interval.map(interval => {
          const intervalToSync = interval.state
          setIntervalSynced({
            commit,
            dispatch,
            intervalToSync,
            journeyId,
            result
          })
        })
      }
    }
  }
}
