import {
  SAVE_HISTORICO,
  SET_TEMPO_ATUAL,
  SET_SYNC,
  SET_HISTORICO,
  SET_INICIO_JORNADA,
  SET_DIRECAO,
  SET_ESPERA,
  SET_INTERVALO_REFEICAO,
  SET_INTERVALO_JORNADA,
  SET_FINAL_JORNADA,
  SET_CARREGAMENTO,
  SET_DESCARREGAMENTO,
  SET_BALANCA,
  SET_FISCALIZACAO,
  SET_TRANSITO,
  SET_ACIDENTE,
  SET_ABASTECIMENTO,
  SET_MANUTENCAO,
  SET_TROCA_PNEU,
  SET_OUTROS,
  SET_DESCANSO,
  SET_PARADA_EMERGENCIAL,
  SET_DESCRIPTION
} from '../constants'

import { registerJourney } from './registerJourney'
import { setSync } from './setSync'

export default {
  [SET_TEMPO_ATUAL]: ({ commit }) => commit(SET_TEMPO_ATUAL),
  [SET_SYNC]: setSync,
  [SET_HISTORICO]({ commit, dispatch }) {
    commit(SET_HISTORICO)
    dispatch(SAVE_HISTORICO)
  },
  [SET_DESCRIPTION]({ commit }, value) {
    commit(SET_DESCRIPTION, value)
  },
  [SET_INICIO_JORNADA]: registerJourney(SET_INICIO_JORNADA, false),
  [SET_DIRECAO]: registerJourney(SET_DIRECAO),
  [SET_ESPERA]: registerJourney(SET_ESPERA),
  [SET_INTERVALO_REFEICAO]: registerJourney(SET_INTERVALO_REFEICAO),
  [SET_INTERVALO_JORNADA]: registerJourney(SET_INTERVALO_JORNADA),
  [SET_CARREGAMENTO]: registerJourney(SET_CARREGAMENTO),
  [SET_DESCARREGAMENTO]: registerJourney(SET_DESCARREGAMENTO),
  [SET_BALANCA]: registerJourney(SET_BALANCA),
  [SET_FISCALIZACAO]: registerJourney(SET_FISCALIZACAO),
  [SET_TRANSITO]: registerJourney(SET_TRANSITO),
  [SET_ACIDENTE]: registerJourney(SET_ACIDENTE),
  [SET_ABASTECIMENTO]: registerJourney(SET_ABASTECIMENTO),
  [SET_MANUTENCAO]: registerJourney(SET_MANUTENCAO),
  [SET_TROCA_PNEU]: registerJourney(SET_TROCA_PNEU),
  [SET_OUTROS]: registerJourney(SET_OUTROS),
  [SET_DESCANSO]: registerJourney(SET_DESCANSO),
  [SET_PARADA_EMERGENCIAL]: registerJourney(SET_PARADA_EMERGENCIAL),
  [SET_FINAL_JORNADA]: registerJourney(SET_FINAL_JORNADA, true, true)
}
