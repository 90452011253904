import { GET_TOKEN, MODULE_NAME as AUTH } from '../store/auth/constants'
export function Geometry({ http, store }) {
  this.$store = store

  this.registerGeometry = async function(geometry) {
    await http
      .post('/geometries/register', geometry, {
        headers: {
          Authorization: `Bearer ${this.$store.getters[`${AUTH}/${GET_TOKEN}`]}`
        }
      })
      .catch(err => {
        if (err.message === 'Network Error') {
          throw new Error('Erro de rede')
        } else if (err.response.status === 401) {
          throw new Error('credencial inválida')
        } else {
          throw err
        }
      })
  }
}
