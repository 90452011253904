import { SAVE_JORNADA, SAVE_HISTORICO, SAVE_SYNC } from '../constants'
import { getKeyJornada, getKeyHistorico, getKeySync } from '../util'

const save = (key, value) => localStorage.setItem(key, JSON.stringify(value))

export default {
  [SAVE_JORNADA]({ state, rootGetters }) {
    const key = getKeyJornada(rootGetters)
    save(key, state.jornada)
  },
  [SAVE_HISTORICO]({ state, rootGetters }) {
    const key = getKeyHistorico(rootGetters)
    save(key, state.historico)
  },
  [SAVE_SYNC]({ state, rootGetters }) {
    const key = getKeySync(rootGetters)
    save(key, state.sync)
  }
}
