import {
  GET_CLIENT_ID,
  GET_TOKEN,
  MODULE_NAME as AUTH
} from '../store/auth/constants'
import { SET_VEHICLES, MODULE_NAME } from '../store/vehicles/constants'
const KEY = 'vehicles'
// import { SET_VEHICLES, MODULE_NAME } from '../store/vehicles/constants'
export function Vehicles({ http, store }) {
  this.$store = store

  this.getVehicles = async function() {
    const clientId = this.$store.getters[`${AUTH}/${GET_CLIENT_ID}`]
    const { data } = await http
      .get(`/vehicle?client_id=${clientId}`, {
        headers: {
          Authorization: `Bearer ${this.$store.getters[`${AUTH}/${GET_TOKEN}`]}`
        }
      })
      .catch(err => {
        if (err.message === 'Network Error') {
          throw new Error('Erro de rede')
        } else if (err.response.status === 401) {
          throw new Error('credencial inválida')
        } else {
          throw err
        }
      })
    localStorage.setItem(KEY, JSON.stringify(data))
    this.$store.commit(`${MODULE_NAME}/${SET_VEHICLES}`, data)
  }
}
