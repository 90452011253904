<template>
  <v-container>
    <login />
  </v-container>
</template>

<script>
import login from '@/pages/system/login'

export default {
  name: 'LoginView',
  components: { login }
}
</script>

<style></style>
