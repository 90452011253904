import { SET_VEHICLE, SET_VEHICLES } from './constants'

export default {
  [SET_VEHICLES](state, vehicles) {
    state.vehicles = vehicles
  },
  [SET_VEHICLE](state, vehicle) {
    state.vehicle = vehicle
  }
}
